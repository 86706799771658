import React, { useState, useEffect } from "react";
import cn from "classnames";
import CountUp from "react-countup";

import { useOnScreen } from "../../hooks/useOnScreen";

import styles from "./styles.module.scss";

const Bar = ({
  className,
  bgClassName,
  value,
  height = 8,
  percentLabel = false,
  percentLabelSuffix = "%",
  valueLabel = false,
  flip = false,
  pill = false,
  animate = false,
  decimals = 0,
  instant = false,
  percentSize = 16,
  flat = false,
}) => {
  const [ref, isOnScreen] = useOnScreen();
  const [animated, setAnimated] = useState(false);

  let displayValue = value;
  let displayPercentLabel = percentLabel;
  let displayValueLabel = valueLabel;
  let opacity = 1;

  if (animate) {
    displayValue = isOnScreen ? value : 0;
    displayPercentLabel = isOnScreen ? percentLabel : 0;
    displayValueLabel = isOnScreen ? valueLabel : 0;
    opacity = isOnScreen ? 1 : 0;
  }

  if (instant) {
    displayValue = animated ? value : 0;
  }

  useEffect(() => {
    if (instant) {
      setAnimated(true);
    }
  }, [instant]);

  return (
    <div className={cn(styles.container)} style={{ height }} ref={ref}>
      <div
        className={cn(bgClassName, styles.background)}
        style={{ height, borderRadius: height }}
      />
      <div
        className={cn(className, styles.bar, {
          [styles.flip]: flip,
          [styles.flat]: flat,
        })}
        style={{ width: `${displayValue}%`, height, borderRadius: height }}
      />

      {percentLabel && (
        <span
          className={styles.label}
          style={{
            left: flip ? "auto" : `${displayValue}%`,
            right: flip ? `${displayValue}%` : "auto",
            opacity,
            fontSize: percentSize,
          }}
        >
          {valueLabel && flip && (
            <span className={styles.valueLabel}>
              (
              <CountUp
                end={displayValueLabel}
                duration={0.5}
                preserveValue
                separator=","
              />
              ){" "}
            </span>
          )}

          <CountUp
            end={displayPercentLabel}
            duration={0.5}
            suffix={percentLabelSuffix}
            preserveValue
            decimals={decimals}
            separator=","
          />

          {valueLabel && !flip && (
            <span className={styles.valueLabel}>
              {" "}
              (
              <CountUp
                end={displayValueLabel}
                duration={0.5}
                preserveValue
                separator=","
              />
              )
            </span>
          )}
        </span>
      )}

      {pill && (
        <div
          className={cn(pill, styles.bar, styles.pill, { [styles.flip]: flip })}
          style={{ width: height, height, borderRadius: height }}
        />
      )}
    </div>
  );
};

export default Bar;
