import React from "react";
import cn from "classnames";
import styles from "./styles.module.scss";

const Box = ({ className, faded = false, children }) => (
  <div className={cn(className, styles.box, { [styles.faded]: faded })}>
    {children}
  </div>
);

export default Box;
