import { useRef } from "react";
import { useScrollPercentage } from "react-scroll-percentage";

export const useOnScreen = (threshold = 0.2, once = true) => {
  const [ref, percentage] = useScrollPercentage({
    threshold
  });

  const isOnScreen = useRef(false);

  if (!isOnScreen.current) {
    isOnScreen.current = percentage > threshold;
  } else if (isOnScreen.current && !once && percentage < threshold) {
    isOnScreen.current = false;
  }

  return [ref, isOnScreen.current];
};
