import overviewData from "../data/overview.json";
import changeData from "../data/industry_change.json";
import forEach from "lodash/forEach";
import find from "lodash/find";
import filter from "lodash/filter";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import orderBy from "lodash/orderBy";
import flatMap from "lodash/flatMap";
import { extent } from "d3-array";

const industries = [];
const years = [];

forEach(overviewData, ({ year, industry }) => {
  if (industries.indexOf(industry) === -1) {
    industries.push(industry);
  }

  if (years.indexOf(year) === -1) {
    years.push(year);
  }
});

export const getYears = () => years;

export const getIndustryNames = () =>
  sortBy(
    map(filter(overviewData, { year: "2015-2022" }), ({ industry }) => industry)
  );

export const getNumberOfIndustries = (timeframe) => {
  const industries = [];

  forEach(overviewData, ({ year, industry, from_perc }) => {
    if (
      String(year) === String(timeframe) &&
      industries.indexOf(industry) === -1 &&
      from_perc !== 0
    ) {
      industries.push(industry);
    }
  });

  return industries.length;
};

export const getNumberOfJobChanges = (timeframe) => {
  let jobChanges = 0;

  forEach(overviewData, ({ year, total }) => {
    if (String(year) === String(timeframe)) {
      jobChanges += total;
    }
  });

  return jobChanges;
};

export const getIndustryForYear = (name, timeframe) => {
  return find(
    overviewData,
    ({ industry, year }) =>
      name === industry && String(year) === String(timeframe)
  );
};

export const getJobChangesForIndustry = (name, timeframe) => {
  const data = getIndustryForYear(name, timeframe);

  return data.total;
};

export const getChangeFromYear = (timeframe) => {
  return find(changeData, ({ year }) => String(year) === String(timeframe));
};

export const getIndustriesForYear = (timeframe) => {
  return filter(overviewData, ({ year }) => String(year) === String(timeframe));
};

export const getTopIndustriesFor = (flow) => {
  return orderBy(
    filter(overviewData, ({ year }) => String(year) === "2015-2022"),
    `${flow}_perc`,
    "desc"
  ).slice(0, 5);
};

export const getOverviewDomain = () => {
  const values = flatMap(
    overviewData,
    ({ to_perc, from_perc, retained_perc }) => [
      to_perc,
      from_perc,
      retained_perc,
    ]
  );

  return extent(values);
};
